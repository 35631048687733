import { gql } from '@apollo/client';
import { MoneyFields } from '../moneyFields';
import { FileFields } from '../fileField';
import { UserIdentification } from '../user/fragments';

export const CredentialFields = gql`
  fragment CredentialFields on CredentialType {
    id
    updatedAt
    username
    secondUsername
    certificate {
      ... FileFields
    }
    authenticationType {
      id
      name
    }
    credentialProvider {
      id
      name
      integrated
      iconUrl
      credentialProviderType {
        id
        name
      }
    }
  }
  ${FileFields}
  `;

export const HasCredentialFields = gql`
  fragment HasCredentialFields on CompanyType {
    hasLendingAcceptedterms @client
    hasSiiCredentials @client
    hasInvoiceProviderCredentials @client
    hasDigitalCertificate
    hasSupplierPortalCredentials @client
    hasFrameworkContract
    validCredentials {
      ... CredentialFields
    }
    currentUserAcceptedTerms {
      id
      product
    }
  }
  ${CredentialFields}`;
export const CompanyDocumentsFields = gql`
  fragment CompanyDocumentsFields on CompanyDocumentManagerType {
    id
    globalAppId
    lastFile
    documentType
    lastDate
    documentDate
  }
`;

export const GET_CUSTOMERS_COMPANY_FRAGMENT = gql`
  fragment GetCustomersCompanyFragment on CompanyType {
    id
    rut
    name
    executiveAssigned {
      ... UserIdentification
    }
    documents {
      ... CompanyDocumentsFields
    }
    recommendedInvoices {
      id
      folio
      dateIssued
      amountWithIva {
        ...MoneyFields
      }
      receiver {
        id
        name
        rut
        displayNationalIdentifier
      }
      preoffer {
        id
        monthlyRate
        defaultRate
      }
    }
    validCredentials {
      ...CredentialFields
    }
    siiCredentials @client {
      ...CredentialFields
    }
    invoiceProviderCredentials @client {
      ...CredentialFields
    }
    supplierPortalCredentials @client {
      ...CredentialFields
    }
    hasLendingAcceptedterms @client
    currentUserAcceptedTerms {
      id
      product
    }
    hasDigitalCertificate
    hasFrameworkContract
    hasSiiCredentials @client
    hasInvoiceProviderCredentials @client
    hasSupplierPortalCredentials @client
  }
  ${CredentialFields}
  ${CompanyDocumentsFields}
  ${MoneyFields}
  ${UserIdentification}
`;

export const MasterEntityBasicField = gql`
  fragment MasterEntityBasicField on MasterEntityType {
    id
    rut
    name
    graphqlId @client
    isNaturalPersonRut
    displayNationalIdentifier
  }
`;
export const MasterEntityPublicInfoType = gql`
  fragment MasterEntityPublicInfoType on MasterEntityPublicInfoType {
    id
    rut
    name
    displayNationalIdentifier
  }
`;

export const CreditLineFields = gql`
  fragment CreditLineFields on CreditLineInfoType {
    id
    limitAmount @client
    lastUpdate @client
    creditUsed @client
    lastMovement {
      id
      totalCreditUsed {
        ...MoneyFields
      }
      amount {
        ...MoneyFields
      }
      createdAt
    }
    currentLimit {
      id
      createdAt
      newLimit {
        ...MoneyFields
      }
    }
  }
  ${MoneyFields}
`;

export const CompanyReceiverCessionsFields = gql`
  fragment CompanyReceiverCessionsFields on CompanyReceiverCessionsHistoryType {
    id
    cessionsNumber
    cessionsMoneyAmount
    historicCessionsNumber
    historicCessionsMoneyAmount
  }
`;

export const CompanyCessionsHistoryFields = gql`
  fragment CompanyCessionsHistoryFields on CompanyCessionsHistoryType {
    id
    cessionsNumber
    cessionsMoneyAmount
    historicCessionsNumber
    historicCessionsMoneyAmount
  }
`;

export const ReceiverCessionsHistoryFields = gql`
  fragment ReceiverCessionsHistoryFields on ReceiverCessionsHistoryType {
    id
    cessionsNumber
    cessionsMoneyAmount
    historicCessionsNumber
    historicCessionsMoneyAmount
  }
`;

export const RateWhitelistFields = gql`
  fragment RateWhitelistFields on RateWhitelistType {
    id
    emitter {
      ... MasterEntityBasicField
    }
    receiver {
      ... MasterEntityBasicField
    }
    monthlyRate
    defaultRate
    retentionRate
    active
    status
  }
`;

export const ContactsField = gql`
  fragment ContactsField on ContactType {
    id
    name
    position
    email
    phoneNumber
    masterEntity {
      id
      name
      rut
    }
  }
`;
export const BaseContactsField = gql`
  fragment BaseContactsField on ContactType {
    id
    name
    position
    email
    phoneNumber
  }
`;

export const EvaluationCommentFields = gql`
  fragment EvaluationCommentFields on EvaluationCommentType {
    id
    createdAt
    comments
    user {
      id
      firstName
      lastName
    }
    masterEntity {
      id
    }
  }
`;

export const CompanyReceiverEvaluationCommentFields = gql`
  fragment CompanyReceiverEvaluationCommentFields on CompanyReceiverEvaluationCommentType {
    id
    createdAt
    comments
    user {
      id
      firstName
      lastName
    }
    masterEntity1 {
      id
    }
    masterEntity2 {
      id
    }
  }
`;

export const ComplianceFields = gql`
  fragment ComplianceFields on ComplianceType {
    id
    readyForTransfer
    status
    requirementsClean
    information
    regcheqId
    signedDate
  }
`;

export const DebtorBasicFields = gql`
  fragment DebtorBasicFields on DebtorType {
    id
    classification {
      id
      code
    }
  }
`;

export const CreditLineMovementFields = gql`
  fragment CreditLineMovementFields on CreditLineMovementType {
    id
    amount {
      ... MoneyFields
    }
    movementType
    createdAt
  }
  ${MoneyFields}
`;

export const CreditLineTransferTypeFields = gql`
  fragment CreditLineTransferTypeFields on CreditLineTransferType {
    id
    transferType {
      id
      name
    }
  }
`;

export const CreditLineEvaluationRequestFields = gql`
  fragment CreditLineEvaluationRequestFields on CreditLineEvaluationRequestType {
    id
    createdAt
    updatedAt
    requestDate
    status
    reEvaluationDate
    resolvedDate
    approvedAmount {
      ... MoneyFields
    }
    comment
  }
  ${MoneyFields}
`;

export const CreditLineSuggestionTypeFields = gql`
  fragment CreditLineSuggestionTypeFields on CreditLineSuggestionType {
    id
    errorLog
    amount {
      ... MoneyFields
    }
    suggestionType
    globalAppId
  }
  ${MoneyFields}
`;

export const CreditlineEvaluationIndicatorsTypeFields = gql`
  fragment CreditlineEvaluationIndicatorsTypeFields on CreditlineEvaluationIndicatorsType {
    id
    legalRepresentativeDicom
    creditorsQuantity
    internalLeverage
    debtMultiplier
    customersConcentration
    dicomRate
    estimatedFlow {
      ... MoneyFields
    }
    bankCreditline {
      ... MoneyFields
    }
    flowCreditline {
      ... MoneyFields
    }
    profitCreditline {
      ... MoneyFields
    }
  }
  ${MoneyFields}
`;

export const CreditlineEvaluationVariablesTypeFields = gql`
  fragment CreditlineEvaluationVariablesTypeFields on CreditlineEvaluationVariablesType {
    id
    debt {
      ... MoneyFields
    }
    avgMonthlyCessions {
      ... MoneyFields
    }
    avgInternalRate
    estimatedInterest {
      ... MoneyFields
    }
    rejectionsPercent
    fixedAssetsPurchasesLastYear {
      ... MoneyFields
    }
    fixedAssets {
      ... MoneyFields
    }
    ipc
    monetaryPolicyRate
    spread
    avgOperationTerm
  }
  ${MoneyFields}
`;

export const CompanyDebtsSummary = gql`
  fragment CompanyDebtsSummary on MasterEntityType {
    debtAmortizationSchedule {
      count
      totalAmount {
        ...MoneyFields
      }
    }
    company {
      id
      pendingReintegrations {
        count
        totalAmount {
          ...MoneyFields
        }
      }
      debtInvoices {
        count
        totalAmount {
          ...MoneyFields
        }
      }
      debtOrdering {
        count
        totalAmount {
          ...MoneyFields
        }
      }
      pendingReplacements {
        count
        totalAmount {
          ...MoneyFields
        }
      }
    }
  }
  ${MoneyFields}
`;

export const ReceiverDebtsSummary = gql`
  fragment ReceiverDebtsSummary on MasterEntityType {
    debtAmortizationSchedule {
      count
      totalAmount {
        ...MoneyFields
      }
    }
    pendingReintegrations {
      count
      totalAmount {
        ...MoneyFields
      }
    }
    debtInvoices {
      count
      totalAmount {
        ...MoneyFields
      }
    }
    debtOrdering {
      count
      totalAmount {
        ...MoneyFields
      }
    }
    pendingReplacements {
      count
      totalAmount {
        ...MoneyFields
      }
    }
  }
  ${MoneyFields}
`;
